import { getBlogPostingSchema, getFaqPageSchema } from '#app/utils/schema'
import { type GetSeoMetaRequest } from '#app/utils/seo'
import { SITE_NAME, SITE_URL } from './config'

export const indexMeta: Record<string, GetSeoMetaRequest> = {
	en: {
		title: 'Best Filipino Dating Site to Meet Filipino Women',
		description:
			'Meet Filipino women from all over the Philippines at the best Filipina dating site - PinayRomances.com. Join free today for fun & safe dating.',
		image: `${SITE_URL}/static/e/index/beauty-of-philipine-girls-16x9.jpg`,
		url: `${SITE_URL}/en`,
		updatedAt: '2022-04-03',
	},
	tl: {
		title: 'Filipina Dating - Meet Filipino Girls & Make New Friends',
		description:
			'Meet Pinay girls from all over the Philippines at the best Filipina dating site - PinayRomances.com. Join free today for fun & safe dating.',
		image: `${SITE_URL}/static/e/index/dating-in-the-philippines.jpg`,
		url: `${SITE_URL}/tl`,
		ogLocale: 'tl_PH',
		updatedAt: '2022-09-08',
	},
}

export function getIndexSchemas(locale: string) {
	const meta = locale === 'tl' ? indexMeta.tl : indexMeta.en

	const blogPosting = getBlogPostingSchema({
		authorName: SITE_NAME,
		dateModified: meta.updatedAt,
		datePublished: meta.updatedAt,
		description: meta.description,
		image:
			locale === 'tl'
				? [
						{
							url: `${SITE_URL}/static/e/index/dating-in-the-philippines.jpg`,
							width: '1200',
							height: '675',
						},
						{
							url: `${SITE_URL}/static/e/index/dating-in-the-philippines-4x3.jpg`,
							width: '1200',
							height: '900',
						},
						{
							url: `${SITE_URL}/static/e/index/dating-in-the-philippines-1x1.jpg`,
							width: '600',
							height: '600',
						},
					]
				: [
						{
							url: `${SITE_URL}/static/e/index/beauty-of-philipine-girls-16x9.jpg`,
							width: '1200',
							height: '675',
						},
						{
							url: `${SITE_URL}/static/e/index/beauty-of-philipine-girls-4x3.jpg`,
							width: '1200',
							height: '900',
						},
						{
							url: `${SITE_URL}/static/e/index/beauty-of-philipine-girls-16x9.jpg`,
							width: '600',
							height: '600',
						},
					],
		title: meta.title,
		url: meta.url,
	})

	return locale === 'tl'
		? blogPosting
		: [
				blogPosting,
				getFaqPageSchema([
					{
						question: 'Why Do Filipino Women like International Guys?',
						answer:
							'Thousands of Americans settled in the Philippines after World War 2. With more US Christian missionaries also taking part, the Philippines has a high English speaking population. This English influence makes for an ideal holiday destination for single guys looking to experience Asian / Filipino culture. An estimated 600,000 US citizens are living in the Philippines.',
					},
					{
						question: 'What Is The Best Way To Start Dating a Filipina?',
						answer:
							'Foreigner men must join a Filipina Dating site due to geographic distance. Filipino women understand this initial hurdle; however, they join PinayRomances for the same reason. Start online by sending likes and messages to many Pinay girls to understand better how an online dating site works. Move to an instant messaging application when you find a good Filipina online.',
					},
					{
						question: 'Filipina Dating - What Are Good Online Dating Tips?',
						answer:
							"Always add profile photos that clearly show your face, as this practice increases your initial contact rate by up to 68%. Use PinayRomances' inbuilt messaging templates for quick contact with Filipino women. Avoid initially speaking about sexually-related topics since women don't like this. Don't assume Filipino girls are prostitutes or they understand English. You must also remember you are not the only man chatting with a girl you contact.",
					},
				]),
			]
}
